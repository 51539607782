.reset-footer{
    background-color: #1B2439;
    color: white;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-top: 5px;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
}