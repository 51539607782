.otp-inputs > div > input {
    background: #ffffff !important;
    /* border: 1px solid #304ffe !important; */
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border: 1px solid #EBEBEB;
    width: 42px !important;
    height: 40px !important;
    color: #252525;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 29px;
    margin-right: 5px;
  }
  
  .otp-inputs > div > input[type="number"]::-webkit-outer-spin-button,
  .otp-inputs > div > input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* .otp-inputs > div > input[type="number"] {
    -moz-appearance: textfield;
  } */
  
  .otp-inputs {
    padding-top: 10px;
  }
  .otp-time{
    margin-left: 120px;
    font-size: 20px;
  }