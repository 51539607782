.close-icon-div {
  display: flex;
  justify-content: flex-end;
}

.popup-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  color: var(--second-main-color);
}

.popup-text {
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: #767676;
}

.custom-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 35vw;
  height: auto;
  background-color: white;
  z-index: 9999;
  overflow: auto;
  outline: none;
  padding: 50px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.custom-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(153, 153, 153, 0.7);
  backdrop-filter: blur(5px);
  z-index: 9998;
  pointer-events: auto;
}

.custom-popup-overlay.hide {
  pointer-events: none;
}

.custom-popup-submit-btn {
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  background: var(--second-main-color);
  border-radius: 5px;
  padding: 5px 40px;
}

.custom-popup-btn {
  font-size: 14px;
  font-weight: 400;
  background: #ffffff;
  border: 1px solid var(--second-main-color);
  border-radius: 5px;
  padding: 5px 40px;
}
