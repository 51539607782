.loginpage {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px !important;
}

.mainpage {
  background: linear-gradient(
      180deg,
      #ffffff 16.43%,
      rgba(255, 255, 255, 0) 48.32%
    ),
    url(/public//images/image3.png);
  background-size: cover;
  height: 120vh;
  z-index: 1000;
}

@media screen and (min-width: 1100px) and (max-width: 1500px) {
  .mainpage {
    height: 140vh;
  }
}

@media screen and (min-width: 1500px) and (max-width: 2000px) {
  .mainpage {
    height: 100vh;
  }
}
