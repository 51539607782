.pc6a-extension {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  text-transform: capitalize;
  color: #103a5d;
  padding-right: 12px;
}

.po-details-top-cards-content {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  text-transform: capitalize;
  color: #103a5d;
  word-wrap: break-word;
  white-space: normal;
}

.po-details-top-cards-header {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #103a5d;
}

.item-1 {
  grid-row: 1/5;
  border-right: 1px solid #ebebeb;
}

.item-2 {
  grid-row: 1/5;
  border-right: 1px solid #ebebeb;
}

.item-5 {
  grid-column: 3/5;
}

.item-3 {
  border-right: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
}

.item-4 {
  border-bottom: 1px solid #ebebeb;
}

.grid-container {
  display: grid;
  border: 1px solid #ebebeb;
  background-color: #fff;
}

.item1 {
  grid-column: 1;
  border-right: 1px solid #ebebeb;
}

.item2 {
  grid-column: 2/5;
}

.item1-1 {
  grid-column: 1;
  grid-row: 1/3;
  border-right: 1px solid #ebebeb;
}

.item2-2 {
  grid-column: 2/5;
  border-bottom: 1px solid #ebebeb;
}

.item3-3 {
  grid-column: 2/5;
}

table {
  border: 1px solid #ebebeb;
}

th {
  border-bottom: 1px solid #ebebeb !important;
  border-right: 1px solid #ebebeb !important;
}

td {
  border-right: 1px solid #ebebeb !important;
}

.po-details-ref {
  border: 1px solid #ebebeb;
  background-color: #fff;
}

.po-details-top-card {
  border: 1px solid #ebebeb;
  background-color: #fff;
  min-height: 200px;
  height: fit-content;
}

.po-details-main-page {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  background: #fafbff;
}

.po-details-desc {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  text-transform: capitalize;
  color: rgba(79, 79, 79, 0.8);
}
.grid-container-print {
  display: grid;
  border:1px solid #0b0b0b !important;
}
.grid-container-print-2 {
  display: grid;
  border-right:1px solid #0b0b0b !important;
  border-left:1px solid #0b0b0b !important;
  border-bottom: 1px solid #0b0b0b !important;
}
.item-1-print{
  grid-row: 1/5;
  border-right: 1px solid #0b0b0b !important;
}

.item-3-print {
  border-right: 1px solid #0b0b0b !important;
  border-bottom: 1px solid #0b0b0b !important;
}

.item-4-print {
  border-bottom: 1px solid #0b0b0b !important;
}
.item1-print {
  grid-column: 1;
  border-right: 1px solid #0b0b0b !important;
}
.item1-1-print {
  grid-column: 1;
  grid-row: 1/3;
  border-right: 1px solid #0b0b0b !important;
}

.item2-2-print {
  grid-column: 2/5;
  border-bottom: 1px solid #0b0b0b !important;
}

.item3-3-print {
  grid-column: 2/5;
}

.po-details-ref-print {
  border-right:1px solid #0b0b0b !important;
  border-left:1px solid #0b0b0b !important;
  border-bottom: 1px solid #0b0b0b !important;
}

.table-cell{
  border: 1px solid #0b0b0b !important;
}
.reference-box{
display: flex;
justify-content: space-between;
}
.reference-component{
border: 1px solid #0b0b0b !important;
}
.pr-details-1-print {
max-width: 10%;
border-right: 1px solid #0b0b0b !important;
}
.top-amount-box-print{
display: block;
border: 1px solid #0b0b0b !important;
}
.purchase-order{
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
}
