

.po-details-back-btn {
  background: #103a5d;
  border-radius: 5px;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-transform: capitalize;
  color: #ffffff;
  border: none;
}
