

/* status */
.delivered {
  background: #e0f8f2;
  color: #43d5ae;
}

.delayed {
  background: #fff3f1;
  color: #fd8a75;
}

.delivering {
  color: #eac52d;
  background: #fcf6dd;
}



/* table btn */


.ven-foll-sp-btn {
  width: 106px;
  height: 28px;
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
  font-family: "Sarabun";
  background:#FFCE31;
  border-radius: 5px;
}


/* datatable.css */

  
  .table-btn {
    width: 90px;
    height: 28px;
    color: #ffffff;
    font-weight: 400;
    font-size: 14px;
    font-family: "Sarabun";
    background: var(--main-color);
    border-radius: 5px;
    margin-right: 1rem!important;
  }
  
  
  
  .um-table-head {
    height: 140px;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    background: #fafbff;
  }
  

  
  .um-entries-div {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  
  .um-entries p {
    font-size: 14px;
    line-height: 18px;
    text-transform: capitalize;
    color: var(--main-color);
    padding-right: 10px;
  }
  
  .um-entries select {
    width: 50px;
    height: 22px;
    background: #ffffff;
    border: 1px solid #ebebeb;
    border-radius: 2px;
    font-size: 15px !important;
  }
  
  .um-entries span {
    padding-left: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-transform: lowercase;
    color: #979797;
  }
  
 
  
  .um-table-header-div {
    border: 1px solid #ebebeb;
  }
  
  .um-show-entries {
    font-weight: 400;
    font-size: 14px;
    color: #999999;
  }
  
  .um-empty-data-table .rdt_Table {
    margin-bottom: 70px !important;
  }

  
 
  
  .um-data-table div::-webkit-scrollbar {
    display: none;
  }
  


  .vendor-follow-visit-table-active {
    border-bottom: 2px solid var(--main-color);
    transition: all 0.2s ease-in-out;
    font-family: "Sarabun" !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    text-decoration: none !important;
    color: var(--main-color) !important;
  }
  
  .vendor-follow-visit-table {
    font-family: "Sarabun" !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    color: #adb2b9 !important;
    text-decoration: none !important;
  }
  
  .vendor-follow-visit-table-badge-active {
    padding: 2px;
    margin-left: 5px;
    background: #103a5d;
    color: white;
    text-decoration: none !important;
  }
  
  .vendor-follow-visit-table-badge {
    margin-left: 5px;
    padding: 2px;
    margin-left: 5px;
    background: #ebebeb;
    color: #999999;
    text-decoration: none !important;
  }
  
  .vendor-foll-table-navbar {
    padding-top: 20px;
    margin-bottom: 10px;
    border-bottom: 2px solid #ebebeb;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .table-status {
    font-family: "Sarabun";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    border-radius: 5px;
    padding: 5px 10px;
    text-transform: capitalize;
    white-space: nowrap;
    /* color: #ffffff; */
  }
  