@import url('https://fonts.googleapis.com/css2?family=Sarabun:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

/* :root {} */

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: auto;
}

body {
  font-family: 'Sarabun', sans-serif;
  line-height: 1.7;
}

a,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Sarabun', sans-serif;
}

a {
  color: #103A5D;
  text-decoration: none !important;
}

a:hover {
  color: #103A5D;
  text-decoration: none !important;
}

p {
  margin: 0 !important;
}

.um-create-btn {
  border: 1px solid #103A5D;
  padding: 5px 50px;
  border-radius: 5px;
  background: #FFF;
  color: #103A5D;
  text-align: center;
  font-size: 20px;
  font-family: Sarabun;
  font-weight: 500;
}

.um-submit-btn {
  border-radius: 5px;
  background: #103A5D;
  padding: 5px 50px;
  color: #FFF;
  text-align: center;
  font-size: 20px;
  font-family: Sarabun;
  font-weight: 500;
}