@media screen and (min-width: 1080px) {
  .main-dashboard-body {
    margin-left: 231px !important;
    background-color: #EFF2F4 !important;
    padding-top: 85px !important;
  }
}

.vendor-dashboard-heading {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  text-transform: capitalize;
  color: #103a5d;
}

.dahsboard-main-page-tables {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  background-color: #ffffff;
}

.dahsboard-main-page-top-head-name {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  text-transform: capitalize;
  color: #103a5d;
}

.show-po-table-top {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #103a5d;
}

.entries-po-table-top {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  text-transform: lowercase;
  color: #979797;
}

.show-select-options {
  background: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 2px;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  text-transform: capitalize;
  color: #979797;
}

.table-show-entries {
  color: #999;
  text-align: center;
  font-family: Sarabun;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
