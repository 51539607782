


.dashboard-main-page-upload {
  background-color: rgb(255, 217, 0);
  color: #fff;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
}
