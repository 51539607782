.po-card {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  background-color: #fff;
}

.po-card-title {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  color: #103a5d;
}

.po-card-count {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  color: #272830;
}

.po-card-view {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  text-decoration-line: underline;
  text-transform: capitalize;
  color: #103a5d;
}
