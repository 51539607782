.not-allowed{
  cursor: not-allowed !important;
}

.sidebar {
  color: rgb(33, 43, 54);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: none;
  background-image: none;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1 0 auto;
  z-index: 1200;
  position: fixed;
  top: 0px;
  outline: 0px;
  left: 0px;
  /* border-right: 1px solid rgba(12, 13, 14, 0.24); */
  background-color: #fff;
  /* box-shadow: 0 3px 10px rgb(0 0 0 / 0.2) */
  box-shadow: 1px 2px 6px -3px rgba(94, 94, 94, 0.7);
}

ul li a:hover {
  text-decoration: none;
}

hr {
  color: black;
}

/* 
.dashboard-top {
    display: flex;
    align-items: center;
    justify-content: space-around;
    text-align: center;

    background-color: rgba(145, 158, 171, 0.12);
    color: black;
    border-radius: 10px;

} */

.dashboard:hover {
  text-decoration: none;
}

/* 
.dashboard-child:hover {
    background-color: rgba(145, 158, 171, 0.12);
    text-decoration: none;
    color: #727272;
    border-radius: 7px;
} */

.sidenavheader {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  text-align: left;
  color: #dfe6f1;
  cursor: pointer;
}

.link-active {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  text-align: left;
  color: #103a5d;
  cursor: pointer;
}

.sidenavheader:hover {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  text-align: left;
  color: #103a5d;
  cursor: pointer;
  transition: 0.3s;
}
