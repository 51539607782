.login-page-left-card {
    background: linear-gradient(207.56deg, rgba(57, 88, 163, 0.5) 24.32%, #2AA8E2 100%), url(/public/images/unsplash_Y5bvRlcCx8k.svg);
    background-size: auto;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-top: 157px;
    padding-left: 25px;
    height: 530px;
    min-width: 608px;
    width: auto;

}

.login-page-right-card {
    display: flex;
    flex-direction: column;
    left: 657px;
    top: 0px;
    background: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-left: 100px;
    padding-top: 50px;
    padding-right: 10px;
    height: 530px;
    /* width: 600px; */
}



.vendor-job-btn {
    background: rgba(255, 255, 255, 0.2);
    color: white;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid white;
    width: 150px;
    height: 39px
}

.bottom {
    display: flex;
    align-content: center;
    justify-content: center;
    margin-top: 150px;
    color: white;
    padding-left: 20px;
}

.bottom-white {
    display: flex;
    align-content: center;
    justify-content: center;
    margin-top: 66px;
    color: #5B6770;
}
.error-msg{
    color: red ;
    font-family: "Roboto";
    font-size: 15px;
    /* margin: 2px 0px 5px 0px; */
}


.model-header {
    text-align: center !important;
}
