.resetcontentpage {
    background-image: url(/public/images/emailpic1.svg);
    /* background-image:url(/public//images/Group289388.svg) ; */
    background-repeat: no-repeat;
    background-size: cover;
    width: auto;
    height: 400px;
    left: 0px;
    top: 0px;
}

.reset-header {
    color:
        #103A5D;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    display: flex;
    align-items: center;
    text-align: center;
}

.password {
    color:
        #AFCA0B;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
}

.reset-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.content {

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #103A5D;
}

.reset-password-btn {

    background: #103A5D;
    border-radius: 5px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
}

/* .footer-card{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
} */