.jobs-expanded-table-div {
  background: #ebebeb;
  padding: 25px 5px 5px 5px;
}

.jobs-expanded-table {
  width: 100%;
  /* table-layout: fixed; */
}

/* .jobs-expanded-table th:first-child,
.jobs-expanded-table td:first-child {
  width: 10%;

}
.jobs-expanded-table th:last-child,
.jobs-expanded-table td:last-child {
  width: 10%; 
} */

.jobs-expanded-table th {
  height: 28px;
  background: #999999;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
  border-bottom: 0px !important;
  /* border-right: 0px !important; */
}

.jobs-expanded-table td {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  text-transform: capitalize;
  background-color: white;
  color: #103a5d;
  border-bottom: 1px solid #dee2e6;
  padding-top: 5px;
  padding-bottom: 5px;
  border-right: 0px !important;
}

.jobs-table .rdt_TableHeadRow div {
  background-color: var(--second-main-color);
}

.jobs-table button:disabled {
  display: none;
}

.job-show-entries {
  color: var(--second-main-color);
  text-align: center;
  font-family: Sarabun;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.jobs-view-btn {
  display: flex;
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: #ffce31;
  color: #fff;
  text-align: center;
  font-family: Sarabun;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}
