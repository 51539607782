@media screen and (min-width: 1024px) {
    .terms-modal {
        width: 80vw;
        margin: auto;
        max-width: 100%;
        padding-top: 20px;
    }
}

@media screen and (min-width: 1640px) {
    .terms-modal {
        width: 60vw;
        height: 80vh;
        margin: auto;
        max-width: 100%;
        padding-top: 80px;
    }
}


.terms-conditions-modelHeader {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    text-align: center !important;
    border-bottom: none !important;
}

.terms-conditions-modelHeader-name {
    font-family: 'Sarabun' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 28px !important;
    color: #103A5D !important;
}

.not-reg-yet {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: var(--second-main-color);
    padding-top: 5px;

}

.decline-btn {
    font-family: 'Sarabun';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    text-align: center;
    color: #5B6770;
    background: #FFFFFF;
    border-radius: 0;
    border: 2px solid rgb(102, 102, 102);
    padding: 5px 50px;

}

.disabled-btn {
    font-family: 'Sarabun';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    text-align: center;
    background-color:
        #8eaadf;
    color: #FFFFFF;
    border-radius: 0;
    padding: 5px 50px;
}

.accept-btn {
    font-family: 'Sarabun';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    text-align: center;
    background-color:
        var(--second-main-color);
    color: #FFFFFF;
    border-radius: 0;
    padding: 5px 50px;
}

.terms-content{
    font-size: 15px;
}