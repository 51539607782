.reset-password-left-card {
    /* background: linear-gradient(207.56deg, rgba(57, 88, 163, 0.5) 24.32%, #2AA8E2 100%), url(/public/images/unsplash_Y5bvRlcCx8k.svg) no-repeat; */
 background-color: #FFFFFF;
    background-size: auto;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-top: 120px;
    padding-left: 25px;
    height: 530px;
    width: 600px;

}

.reset-password-right-card {
    display: flex;
    flex-direction: column;
    left: 657px;
    top: 0px;
    background: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-left: 100px;
    padding-top: 20px;
    padding-right: 10px;
    height: 530px;
}



.job-btn {
    background: rgba(255, 255, 255, 0.2);
    color: white;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid white;
    width: 150px;
    height: 39px
}



.reset-sub-content {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #5B6770;
}