.news-page-body {
  padding-top: 1rem;
}

.announcement-news-top {
  display: flex;
  justify-content: space-between;
  margin-left: 130px;
  margin-right: 130px;
}

.announcement-news-update {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  text-transform: capitalize;
  color: var(--second-main-color);
}

.announcement-news-seeall {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  text-transform: capitalize;
  color: #94a0ad;
}

.press-entries {
  color: var(--second-main-color);
  text-align: center;
  font-family: Sarabun;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
