.submit-img {
  display: block;
  margin: 0 auto;
  width: 30%;
}
.submit-text {
  display: flex;
  justify-content: center;
  color: #767676;
  font-size:24px;
  font-family: Roboto;
font-style: Medium;
}
.button-ok {
  height: 50px;
  border-radius: 5px;
  background-color: var(--second-main-color);
  text-align: center;
  color: #ffffff;
  width: 140px;
  font-family: "Sarabun";
  font-style: normal;
  margin-bottom: 50px;
  font-size: 28px;
}
.final-button{
    display: flex;
    justify-content: center;
}
.submit-done{
    display: flex;
    justify-content: center;
    color: var(--second-main-color);
}

/* .popup-content {
  margin: auto;
  background: #fff;
  width: 60%;
  padding: 5px;
  border: 1px solid #d7d7d7;
  height: 500px;
  padding-top: 78px;
} */
.success-popup{
  display: flex;
  align-items: center;
  justify-content: center;
}
.success-popup .modal-content{
  position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: 0.3rem;
    outline: 0;
    width: 450px;
    height: 400px;
}