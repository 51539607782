:root {
  --color-white: #ffffff;
  --main-color: #103a5d;
  --gray-text: #adb2b9;
  --disabled-background-color: #e8e7e7;
  --disabled-text-color: #888;
  --second-main-color:#003595;
}

button:disabled,
input:disabled,
select:disabled,
textarea:disabled,
label:disabled {
  cursor: not-allowed;
  background-color: var(--disabled-background-color) !important;
  color: var(--disabled-text-color) !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;

}

input:-webkit-autofill{
  background: transparent!important;
}
 input:-moz-autofill {
  background-color: #ffffff !important;
}

option[hidden] {
  color: grey !important; /* Change to your desired color */
  font-style: italic; /* Add any other styling as needed */
}

input{
  font-family: sans-serif , "Sarabun";
}