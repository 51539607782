
.pr-release-multiselect {
  width: 150px;
}

.pr-release-multiselect .multi-select__value-container {
  flex-wrap: wrap;
}

.pr-release-multiselect .multi-select__multi-value {
  max-width: 120px; 
  white-space: normal; 
  overflow-wrap: break-word; 
}
/* Apply button */
.pr-release-apply-button {
  display: flex;
  padding: 5px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 5px;
  background: #103a5d;
  color: white;
  cursor: pointer;
}

/* Modal */
.pr-release-filter-modal {
  position: absolute;
  width: -moz-fit-content;
  width: fit-content;
  top: 100%;
  margin-top: 24px;
  margin-left: 260px;
  background-color: #ebebeb;
  padding: 6px;
}
.pr-release-filter-modal-not-login {
  position: absolute;
  width: -moz-fit-content;
  width: fit-content;
  top: 100%;
  margin-top: 422px;
  margin-left: 260px;
  background-color: #ebebeb;
  padding: 6px;
}

/* Dropdown heading style */
.pr-release-multiselect .dropdown-heading {
  height: 32px;
}

.pr-rele-multiselect-row {
  display: flex;
  justify-content: flex-start;
  gap: 5px;
  align-items: center;
  width: 100%;
}

.pr-release-multiselect .options {
  color: #9f9c9c;
  background-color: #f4f4f4;
}

.pr-release-multiselect [type="checkbox"] {
  min-width: 15px;
  width: 15px;
  height: 15px;
  border: 1px solid #999999;
  appearance: none;
  accent-color: #103a5d;
  cursor: pointer;
  position: relative; /* Added for positioning the checkmark */
}

.pr-release-multiselect [type="checkbox"]:checked {
  background-color: #103a5d;
  border: none;
}

.pr-release-multiselect [type="checkbox"]:checked::before {
  content: "\2713"; /* Unicode checkmark character */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center the checkmark */
  font-size: 14px; /* Adjust the size of the checkmark */
  color: white; /* Color of the checkmark */
}

.pr-rele-labels label {
  color: #103a5d;
  font-family: Sarabun;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 10px;
}
.pr-bid-end-inp {
  display: flex;
  padding: 9px;
  align-items: center;
  gap: 61px;
  border-radius: 5px;
  border: 1px solid #adb2b9;
  background: #fff;
}

.pr-rel-inp-tit-text input {
  display: flex;
  width: 100%;
  padding: 10px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid #adb2b9;
}

/* @media (min-width: 1356px) {
  .pr-release-filter-modal {
    margin-top: 1090px;
  }
}

@media (min-width: 1750px) {
  .pr-release-filter-modal {
    margin-top: 690px;
  }
} */
