.pr-details-jobs-user-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 74vw;
  height: 100%;
  background-color: white;
  z-index: 9999;
  overflow: auto;
  outline: none;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.pr-details-jobs-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(153, 153, 153, 0.7);
  backdrop-filter: blur(5px);
  z-index: 9998;
  pointer-events: auto;
}

.pr-details-jobs-title-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pr-details-jobs-title-div h2 {
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  text-transform: capitalize;
  color: var(--second-main-color);
}

/* scrollBar css*/
.pr-details-jobs-main-scroll {
  max-height: 500px;
  overflow-y: auto;
}

.pr-details-jobs-main-scroll::-webkit-scrollbar {
  width: 15px;
  /* height: 100px; */
  flex-shrink: 0;
}

.pr-details-jobs-main-scroll::-webkit-scrollbar-track {
  background: #d9d9d9;
  border: 1px solid #999999;
  border-radius: 20px;
}

.pr-details-jobs-main-scroll::-webkit-scrollbar-thumb {
  width: 21.408px;
  height: 1px;
  flex-shrink: 0;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  background: var(--second-main-color);
  border: 1px solid #999999;
  border-radius: 20px;
}

.pr-details-pr-title {
  width: 50%;
}

.pr-details-pr-title label {
  color: var(--second-main-color);
  font-family: Sarabun;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 13px;
}

.pr-details-pr-title input {
  display: flex;
  width: 100%;
  height: 38px;
  padding: 10px;
  align-items: flex-start;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid #adb2b9;
}

.pr-details-jobs-main-container {
  width: 100%;
  height: 100%;
  padding: 20px;
  background: #fafbff;
  border: 0.5px #ebebeb solid;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
}

.pr-details-jobs-header {
  color: var(--second-main-color);
  font-size: 18px;
  font-family: "Sarabun";
  font-weight: 400;
  text-transform: capitalize;
  word-wrap: break-word;
}

.pr-details-jobs-card-qtn {
  height: auto;
  width: 100%;
  padding: 10px;
  background: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 0.5px #999999 solid;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  display: flex;
}

.quotation-pr-title {
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  margin: 30px 3px;
  width:100%;
}

.quotation-pr-note-text {
  color: var(--second-main-color);
  font-family: Sarabun;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.pr-note-textarea {
  width: 100%;
  align-self: stretch;
  flex: 1 1 0;
  padding: 10px;
  border-radius: 5px;
  border: 0.5px #ebebeb solid;
  display: inline-flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
}
.pr-note-textarea textarea {
  resize: none;
  color: #383636;
  font-family: Sarabun;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.pr-details-date-main {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
}

.pr-details-date-main label {
  color:var(--second-main-color);
  font-family: Sarabun;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 13px;
}

.pr-details-date-main input {
  width: 100%;
  padding: 5px;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid #adb2b9;
}

.pr-details-bidd-deli-text {
  width: 30%;
}

.create-btns-div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.pr-details-jobs-submit-btn {
  /* width: 104px;
        height: 28px; */
  font-size: 20px;
  font-weight: 500;
  color: #ffffff;
  background: var(--second-main-color);
  border-radius: 5px;
  padding: 5px 40px;
}

.pr-combined-detail-card-item{
  width: 100%;
  height: 100%;
  padding: 10px;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
}

.pr-combined-detail-card-title{
  color: var(--second-main-color);
  font-size: 16px;
  font-family: "Sarabun";
  font-weight: 400;
  text-transform: capitalize;
  word-wrap: break-word;
}

.pr-combined-detail-card-content[type="text"]{
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 0.5px #ebebeb solid;
  color: #4c4b4b;
  font-size: 14px;
  font-family: "Sarabun";
  font-weight: 400;
  text-transform: capitalize;
  word-wrap: break-word;
}

.pr-combined-detail-card-content [type="textarea"]{
  width: 100%;
  height: 100px;
  padding: 10px;
  border: 0.5px #ebebeb solid;
  color: #4c4b4b;
  font-size: 14px;
  font-family: "Sarabun";
  font-weight: 400;
  word-wrap: break-word
}

textarea.pr-combined-detail-card-content{
  height: 32%;
  resize: none;
  border: 1px solid #ebebeb;
  display: flex;
  padding: 10px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  color: #4c4b4b;
  font-family: Sarabun;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize
}

.pr-details-jobs-cancel-btn{
  color:var(--second-main-color);
  background-color: white;
  border: 1px solid var(--second-main-color);
}


.pr-details-header {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  text-transform: capitalize;
  color: #103a5d;
}

.pr-details-view-file {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #eb0a45;
  border-radius: 5px;
  cursor: pointer;
}

.pr-details-view-file-name {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  text-transform: capitalize;
  color: #ffffff;
}

.pr-details-view-file-image {
  width: 20px;
  height: 20px;
}

.pr-details-chat {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #103a5d;
  border-radius: 2px;
  border: none;
  height: 30px;
}

.pr-details-chat-name {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  color: #ffffff;
}

.pr-details-sub-heading {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  color: #103a5d;
}

.pr-details-input-style {
  border: 1px solid rgb(233, 233, 233);
  border-radius: 2px;
  padding: 5px;
}

.upload-pr-file {
  width: 100%;
  border: 1px dashed black;
  background-color: #fff;
  margin-top: 4px;
  padding: 5px;
}

.textarea-pr-details {
  width: 96% !important;
  border: 1px solid rgb(233, 233, 233);
  border-radius: 2px;
  background: #f5f8fe;
}

.combined-card{
  margin: 8px 0px;
}


