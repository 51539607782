.announcement-page-background {
    background: linear-gradient(267.36deg, rgba(57, 88, 163, 0.5) -0.63%, #2AA8E2 98.42%), url(/public/images/unsplash_xuTJZ7uD7PI.svg), url(/public//images/image8.svg);
    background-size: auto;
    height: 300px;
    z-index: 1000;


}

.announcement-jobs-top-header {
    font-family: 'Sarabun';
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
}

.announcement-jobs-top-search {
    width: 40%;
    border-radius: 8px;
    padding-left: 35px !important; 
    background-image: url("../../Assets/Svg/search-icon.svg");
    background-repeat: no-repeat;
    background-position: 10px center; /* Adjust the position of the icon */
    background-size: 20px; /* Adjust the size of the icon */   
}



.jobs-body {
    margin-top: 4rem;
}

.announcement-table-nav-active {
    border-bottom: 2px solid var(--second-main-color);
    transition: all 0.2s ease-in-out;
    font-family: 'Sarabun';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    color: var(--second-main-color);
}

.announcement-table-nav {
    font-family: 'Sarabun';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    color: rgba(146, 146, 146, 0.952);
}

.nav {
    border-bottom: 1px solid #EBEBEB;
}

.announcement-top-badge {
    margin-left: 5px;
    padding: 2px;
    margin-left: 5px;
    background: #EBEBEB;
}

.announcement-top-badge-active {
    padding: 2px;
    margin-left: 5px;
    background: var(--second-main-color);
    color: white;
}

.filter-button {
    background: var(--second-main-color);
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #FFFFFF;
    width: 100px;
    border-radius: 0px;

}

.show-select-options {
    border: 1px solid gray;
    background-color: #FFFFFF;

}