.news-card-image {
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 12px;
  width: 200px;
  height: 200px;
}

.news-card-image img {
  width: 180px;
  height: 180px;
}

.news-card-body {
  display: flex;
  justify-content: flex-start;
  margin-left: 120px;
  margin-right: 120px;
  background: rgba(235, 235, 235, 0.26);
  border: 1px solid #adb2b9;
  border-radius: 12px;
}

.news-card-top-heading-content {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #103a5d;
}

.news-card-para-content {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  color: #103a5d;
}

.news-card-date {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
  margin-top: auto !important;
  margin-bottom: 10px !important;
  color: #94a0ad;
}
