.pagination-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 10px;
}

.pagination-container button {
  border: 1px solid #ccc;
  padding: 0px 10px;
  background-color: white;
  color: var(--second-main-color);
  font-family: SANS-SERIF;
  font-weight: 400;
  font-feature-settings: 'clig' off, 'liga' off;

}

.pagination-container button.active {
  background-color: var(--second-main-color);
  color: white;
}

.inner-pagination-container button {
  color: var(--main-color);
}

.inner-pagination-container button.active {
  background-color: var(--main-color);
}