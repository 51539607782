.dashboard-main-page-pr-view {
  background-color: rgb(255, 217, 0);
  color: #fff;
  padding: 5px 15px;
  border: none;
  border-radius: 5px;
  white-space: nowrap;
}

.dashboard-main-page-award {
  background-color: rgb(210, 255, 230);
  color: rgb(0, 180, 141);
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  white-space: nowrap;
}

.status-declined {
  color: #fd8a75;
  background: #fff3f1;
}

.status-bidding {
  background: #dfe6f1;
  color: #428bc1;
}
.status-confirming-po{
  color: #FFCE31;
  background: #FCF6DD;
}

.dashboard-main-page-download {
  background: #103a5d;
  color: white;
  padding: 5px 4px;
  border: none;
  border-radius: 5px;
  white-space: nowrap;
}

.dashboard-main-page-upload {
  background-color: rgb(255, 217, 0);
  color: #fff;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
}
