.upper-container {
  top: 0px;
  text-align: center;
  position: relative;
  /* height: 100vh; */
  /* left: 439px; */
  margin-left: 25%;
  height:30vh;
  justify-content: space-around;
}

.upper-container .box {
  display: flex;
 flex-direction: row;
  color: white;
  background-color: #1e3a5c;
  /* width: 600px;
  margin-top: 150px; */
  width:60%;
  margin-top: -6%;
}
@media screen and (min-width:250px) and (max-width:560px) {
    .upper-container .box {
        display: flex;
        color: white;
        background-color: #1e3a5c;
        width:100%;
        margin-top: -6%;
      }
      .upper-container {
        top: 0px;
        text-align: center;
        position: relative;
        margin-left: 0%;
        height:30vh;
        justify-content: space-around;
      }

     

}
.announce-card{
  height: 140px;
  width: 140px;
  background-color: white;
  color:var(--second-main-color);
  text-align: center;
  display: grid;
}
.announce-card:hover {
  cursor: pointer; 
}
.announce-card:active {
  cursor: grabbing; 
}


.selected{
  background-color:  var(--second-main-color);
  color:white;
  display: grid;
}
.main-announce-card{
  display: flex;
  z-index: 5;
  position: absolute;
}
.announce-tags{
margin-top: -40px;
padding: 10px 20px 20px 10px;
font-family: 'Sarabun';
font-style: normal;
font-weight: 600;
font-size: 14px;
}
.announce-images{
padding: 20px 30px 0px 40px;
}
