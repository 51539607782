/* .announcement-page-background {
    background: linear-gradient(267.36deg, rgba(57, 88, 163, 0.5) -0.63%, #2AA8E2 98.42%), url(/public/images/unsplash_xuTJZ7uD7PI.svg), url(/public//images/image8.svg);
    background-size: auto;
    height: 300px;
    z-index: 1000;


} */

.announcement-jobs-top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 120px;
}

.vendor-follow {
  /* margin-top: 4rem; */
  padding-top: 90px;
}

/* .vendor-follow-table-nav-active {
  border-bottom: 1px solid rgb(0, 0, 68);
  transition: all 0.2s ease-in-out;
  font-family: "Sarabun" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  color: var(--second-main-color) !important;
} */

/* .vendor-follow-table-nav {
  font-family: "Sarabun" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  color: rgba(16, 58, 93, 0.6) !important;
} */

.show-vf-table-top {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: #103a5d;
}

.entries-vf-table-top {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  text-transform: lowercase;
  color: #979797;
}

.nav {
  border-bottom: 1px solid #ebebeb;
}

.vendor-follow-top-badge {
  margin-left: 5px;
  padding: 2px;
  margin-left: 5px;
  background: #ebebeb;
}

.vendor-follow-top-badge-active {
  padding: 2px;
  margin-left: 5px;
  background: #103a5d;
  color: white;
}

.show-select-options {
  border: 1px solid gray;
  background-color: #ffffff;
}

.table-status-Delivered {
  color: #43d5ae;
  width: 100%;
  height: 60%;
  border-radius: 5px;
  font-family: Sarabun;
  font-size: 14px;
  background-color: #e0f8f2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-status-Delivering {
  color: #ffce31;
  width: 100%;
  height: 60%;
  border-radius: 5px;
  font-family: Sarabun;
  font-size: 14px;
  background-color: #fcf6dd;
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-status-Delayed {
  color: #fd8a75;
  width: 100%;
  height: 60%;
  border-radius: 5px;
  font-family: Sarabun;
  font-size: 14px;
  font-style: bold;
  background-color: #fff3f1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.vendor-follow-table-button {
  width: 90px;
  height: 28px;
  border-radius: 5px;
  background-color: #103a5d;
  color: #ffffff;
  border: none;
}

.vendor-follow-penalty {
  width: 100%;
  height: 60%;
  border-radius: 5px;
  color: #ffffff;
  background-color: #ffce31;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Sarabun;
  font-size: 14px;
  text-align: center;
}

.vendor-follow-header {
  color: #103a5d;
  font-family: Sarabun;
  font-size: 28px;
  font-style: bold;
  font-weight: 700;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.vendor-follow-main-div {
  display: inline-block;
  width: 80%;
  margin-left: 248px;
}

.card-body {
  background-color: #fafbff;
}

.dtqzBx div:first-child {
  color: #103a5d;
}

.nav-link:focus,
.nav-link:hover {
  /* color: #ca0a0a; */
  color: #103a5d;
}

.containerr {
  width: 100%;
}

.vendor-follow-csv-logo {
  display: flex;
  justify-content: flex-end;
}

.vendor-follow-show-and-csv {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.penalty-header {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  color: #103a5d;
  padding-left: 0px;
  padding-right: 30px;
}

.penalty-popup-label {
  color: #103a5d;
  font-size: 20px;
  /* Font family: Sarabun
Font style: Regular
Font size: 20px
Line height: 26px
Line height: 100%
Align: Left
Vertical align: Top
Fill: Solid
#103A5D */
}

.penalty-popup {
  width: 100%;
}

.modal-content1 {
  width: 80vw;
  height: 90vh;
  margin: auto;

  padding-top: 80px;
  background-color: white;
}

.confirm {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  min-width: 70%;
  height: auto;
  background-color: white;
  z-index: 9999;
  overflow: auto;
  outline: none;
  padding: 20px;
}

.penalty-claim-header {
  display: flex;
  justify-content: space-between;
}

.penalty-claim-header-text {
  margin-right: 20px;
  color: #103A5D;
  font-family: Sarabun;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}

.penalty-claim-header-button {
  background-color: #eb0a45;
  color: white;
  width: 175px;
  height: 40px;
  font-size: 18px;
  border: 0px;
  border-radius: 5px;
}

.penalty-input-label {
  color: #103a5d;
  font-size: 20px;
}

.penalty-labels {
  margin-top: 30px;
}

.penalty-accept-reject {
  margin-top: 20px;
  display: flex;
  justify-content: end;
  padding-right: 30px;
  gap: 10px;

  padding-bottom: 12px;
  margin-bottom: 12px;
}

.reject {
  color: #103a5d;
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid #103a5d;
  width: 100px;
  font-size: 14px;
}

.accept {
  background-color: #103a5d;
  color: #ffffff;
  border-radius: 5px;
  border: 1px solid #103a5d;
  width: 100px;
  font-size: 14px;
}

.penalty-close-button {
  background-color: #ffffff;
  border: 0px;
  padding-right: 31px;
}

.penalty-input {
    max-width: 100% !important;
    width: 100% !important;
    border: none !important;

}

.penalty-input-box {
  border: 1px solid #ebebeb;
  border-radius: 5px;
  width:98%;
  height: 40px;
  padding: 5px;
  text-transform: capitalize;
  color: #3c3b3b;
}

.reason-reject-penalty {
  position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    min-width: 70%;
    height: auto;
    background-color: white;
    z-index: 9999;
    overflow: auto;
    outline: none;
    padding: 20px;
}

.reject-submit {
  background-color: #103a5d;
  color: #ffffff;
  border-radius: 5px;
  border: 1px solid #103a5d;
  width: 100px;
  height: 26px;
  font-size: 14px;
  margin-bottom: 27px;
}

.attach-file-reject {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-right: 30px;
}

.reject-attach-text {
  color: #103a5d;
  font-size: 18px;
  font-weight: bold;
}

.reject-ptext {
  color: #999999;
  font-size: 14px;
}

.line-in-reject {
  border: 1px solid #999999;
  width: 98%;
}

.reason-submit {
  display: flex;
  justify-content: end;
  margin-top: 20px;
  margin-right: 30px;
}

.areyousure {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
}

.areyousure-close-button {
  /* display: flex;
  justify-content: end; */
  background-color: #ffffff;
  border: 0px;
  /* padding-right: 20px; */
  /* margin-left: 600px; */
  margin-left: 697px;
  margin-top: -22px;
}

.reason-reject-ptext {
  padding-bottom: 22px;
  color: #767676;
  font-family: Sarabun;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.areyousure-penalty {
  width: 800px;
  /* height: 70vh; */
  margin: auto;
  padding-top: 50px;
  background-color: white;
  margin-left: 100px;
  /* padding-left: 50px; */
  margin-top: 10px;
}

.cancel-confirm-penalty {
  display: flex;
  gap: 20px;
  margin-bottom: 4px;
}

.reject-cancel {
  color: #103a5d;
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid #103a5d;
  width: 100px;
  height: 26px;
  font-size: 14px;
}

.success-ptext {
  padding-bottom: 30px;
    color: #767676;
    font-family: Sarabun;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.success-done {
  margin-top: 20px;
  color: #103A5D;
  font-family: Sarabun;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.my-popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}

.accept-penalty {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  min-width: 70%;
  height: auto;
  background-color: white;
  z-index: 9999;
  overflow: auto;
  outline: none;
  padding: 20px;

}

.penalty-accept-header-text {
  margin-right: 20px;
  font-style: bold;
  margin-top: 20px;
  color: #103A5D;
  font-family: Sarabun;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.vendor-follow-text {
  display: flex;
}

.vendor-follow-csv-logo {
  display: flex;
  justify-content: flex-end;
}

.vendor-follow-attachment-list{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 17px;
}
.ven-foll-fn-fs{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3px;
  }
.vendor-follow-attachment-item{
  display: inline-flex;
  padding: 5px 30px;
  align-items: flex-start;
  gap: 10px;
  border: 1px solid #EBEBEB;
  background: #FDFDFF;
}
.vendor-follow-attac-it-file-name{
  color: #103A5D;
  font-family: Sarabun;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.vendor-follow-attac-it-file-size{
  color: #999;
  font-family: Sarabun;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.vendor-follow-pdf-icon{
  max-width: 31px;
  height: auto;
  cursor: pointer;
}
.vendor-follow-cancel-icon{
  max-width: 12px;
    height: auto;
    cursor: pointer;
    position: relative;
    top: -8px;
    left: 34px;
}
.error-message {
  color: red;
}

.signature-box{
  display: none;
}

.penalty-title {
  width: 1100px;
  border-radius: 5px;
  border: 1px solid #ebebeb;
}
