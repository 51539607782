

.pr-table-nav-active {
  border-bottom: 2px solid var(--main-color);
  transition: all 0.2s ease-in-out;
  font-family: "Sarabun" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  color: var(--main-color) !important;
}

.pr-table-nav {
  font-family: "Sarabun" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  color: rgba(16, 58, 93, 0.6) !important;
}

.pr-top-badge {
  margin-left: 5px;
  padding: 2px;
  margin-left: 5px;
  background: #ebebeb;
}

.pr-top-badge-active {
  padding: 2px;
  margin-left: 5px;
  background: var(--main-color);
  color: white;
}

.show-pr-table-top {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #103a5d;
}

.entries-pr-table-top {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  text-transform: lowercase;
  color: #979797;
}
