/* .header {
    background-image: url(/public/images/Rectangle2380.svg);
    background-size: auto;
    height: 100vh;
} */

.navigation {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.icon {
  background-color: var(--second-main-color);
  color: white;
  height: 35px;
  width: 35px;
  padding: 10px;
  border-radius: 50px;
}

.navheader {
  color: #5b6770;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
}

.logo {
  height: 35px;
  cursor: pointer;
}

.dropdown-toggle-btn {
  border: 1px solid black;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-top: 10px;
  padding-left: 5px;
}

.actve {
  background-color: #182747;
  color: white;
}
.list-group-flush {
  border-radius: 5px !important;
}

.list-group-item {
  cursor: pointer;
}
.list-group-item:hover {
  background-color: #182747;
  color: white;
  cursor: pointer;
}
.dropdown-menu {
  padding: 0 !important;
  min-width: none !important;
}

 .dropdown-toggle::after{
  content: url("../../Assets/Svg/drop-down-icon.svg");
  border-top: 0px;
  border-left: 0px;
}