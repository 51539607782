.upload-po-main-page {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.upload-po-main-heading {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  display: flex;
  text-transform: capitalize;
  color: #103a5d;
}

.upload-po-main-para {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #999999;
}

.upload-po-cancel-btn {
  border: 1px solid #103a5d;
  border-radius: 5px;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #103a5d;
  background-color: #fff;
}

.upload-po-upload-btn {
  border: 1px solid #103a5d;
  background: #103a5d;
  border: none;
  border-radius: 5px;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
}

.upload-po-body{
  background-color: #fff !important;
}