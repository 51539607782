.main-container {
  left: 0px;
  top: 449px;
  width: 100%;
  background: #eff2f4;
}

.form-container {
  left: 150px;
  top: 918px;
  background: #ffff;
  margin: 30px;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 50px;
  padding-bottom: 20px;
}

.header-registration {
  color: var(--second-main-color);
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  gap: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.header-registration-two {
  color: #103a5d;
  font-size: 20px;
}

.condition {
  color: #999999;
  font-size: 16px;
}

.vendor-page-container {
  height: 48px;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 36px;
  margin: 30px;
  color: #103a5d;
  padding-bottom: 5px;
  text-transform: capitalize;
}

.vendor-input-label {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  margin-top: 15px;
  color: #103a5d;
  margin-bottom: 15px;
}

.textbox-container {
  display: flex;
  width: 100%;
  align-items: baseline;
}

.left-container {
  width: 100%;
  margin-right: 20px;
}

.left-container-hidden-input {
  width: 49%;
  margin-right: 20px;
}

.left-Username-container {
  width: 100%;
  margin-right: 20px;
}

.input-border {
  border: 1px solid #adb2b9;
  border-radius: 10px;
  width: 50%;
  margin-bottom: 15px;
  height: 40px;
  font-size: 15px;
  color: #000000;
}

.registration {
  width: 100%;
  color: #000000;
  padding-left: 10px;
}
.registration-max {
  width: 100%;
  color: #000000;
  padding-left: 10px;
  margin-right: 60px;
}
.doesyourcompany {
  width: 100%;
  color: #000000;
  padding-left: 10px;
  margin-top: 26px;
}

.registration-max {
  width: 100%;
  color: #000000;
  padding-left: 10px;
  margin-right: 15px;
}

.right-container {
  width: 100%;
}

.registration-left {
  width: 100%;
  padding-left: 10px;
}

.registration-left-date {
  width: 100%;
  text-align: right;
  padding-right: 10px;
}

.fifty-width {
  width: 25%;
}

.right-container-box {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.rightside-container {
  width: 50%;
  margin-right: 20px;
}

.leftside-container {
  width: 50%;
}

.mini {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 530;
  font-size: 11px;
  line-height: 18px;

  color: #999999;
}

.contact {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  color: #103a5d;
}

.contact-two {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  color: #103a5d;
  padding-top: 35px;
}

.registeredaddress {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  margin-top: 30px;
  color: #103a5d;
}

.staricon {
  color: red;
}

input[disabled] {
  background: #ebebeb;
}

.button-submit-container {
  display: flex;
  justify-content: flex-end;
}

.button-submit {
  height: 50px;
  border-radius: 5px;
  background-color: var(--second-main-color);
  text-align: center;
  color: #ffffff;
  width: 180px;
  font-family: "Sarabun";
  font-style: normal;
  margin-bottom: 50px;
  margin-right: 30px;
  font-size: 28px;
}

@media screen and (max-width: 600px) {
  .radio-container {
    flex-direction: column;
  }

  .commercial {
    width: 100%;
  }
  .registration-left {
    width: 100%;
    padding-left: 10px;
  }
  .textbox-container {
    flex-direction: column;
  }

  .radio-label {
    padding-top: 10px;
  }

  .right-container-box {
    display: flex;
    flex-direction: column;
  }

  .rightside-container {
    margin-right: 0px;
    width: 100%;
  }

  .leftside-container {
    width: 100%;
  }
}

@media screen and (min-width: 600px) and (max-width: 820px) {
  .right-container-box {
    display: flex;
    flex-direction: column;
  }

  .rightside-container {
    margin-right: 0px;
    width: 100%;
  }

  .leftside-container {
    width: 100%;
  }

  .textbox-container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}

select {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #c4c5c7;
}

.horziantal {
  width: 100%;
  text-align: left;
  margin-left: 0;
}

.buttons {
  display: flex;
  justify-content: space-between;
}

.button-submit-back {
  height: 50px;
  border-radius: 5px;
  background-color: var(--second-main-color);
  text-align: center;
  color: #ffffff;
  width: 180px;
  font-family: "Sarabun";
  font-style: normal;
  margin-bottom: 50px;
  margin-left: 30px;
  font-size: 28px;
}

.vendor-input-label-anylitigation {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
  margin-top: 15px;
  color: #103a5d;
  margin-bottom: 15px;
}

.form-container-with-bottomspace {
  left: 150px;
  top: 918px;
  background: #ffff;
  margin: 30px;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 50px;
  padding-bottom: 400px;
}

.text-above-the-horizantalline {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: #103a5d;
}

.right-container-inSupplier {
  width: 50%;
  margin-right: 20px;
}

.left-container-inSupplier {
  margin-right: 20px;
}

.maximum-minimum {
  display: flex;
  width: 100%;
  gap: 10px;
  align-items: center;
}

.maximum-minimum-tags {
  display: flex;
  width: 100%;
  align-items: center;
  /* justify-content: space-between; */
}

.minimum {
  color: #103a5d;
  width: 54%;
}

.maximum {
  color: #103a5d;
}

@media screen and (min-width: 600px) and (max-width: 820px) {
  .left-container-inSupplier {
    width: 100%;
  }

  .right-container-inSupplier {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .left-container-inSupplier {
    width: 100%;
  }

  .right-container-inSupplier {
    width: 100%;
  }
}

.vendor-input-label-financial-project-ref {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  margin-top: 15px;
  color: #103a5d;
  margin-bottom: 38px;
}

.vendor-input-label-hse {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  margin-top: 15px;
  color: #103a5d;
  margin-bottom: 15px;
}

.form-container-with-bottomspace-finanacial {
  left: 150px;
  top: 918px;
  background: #ffff;
  margin: 30px;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 50px;
  padding-bottom: 600px;
}

.registration-hse {
  width: 50%;
  color: #000000;
  padding-left: 10px;
}

.button-submit-container-hse {
  display: flex;
  justify-content: flex-end;
}

.button-submit-hse {
  height: 50px;
  border-radius: 5px;
  background-color: #0b1d35;
  /* top:20px; */
  text-align: center;
  color: #ffffff;
  width: 200px;
  font-family: "Sarabun";
  font-style: normal;

  margin-left: 30px;
  font-size: 18px;
}

.button-input-container {
  display: flex;
}

.hse-mini {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* width: 520px; */

  color: #999999;
}

.form-container-with-bottomspace-hse {
  left: 150px;
  top: 918px;
  background: #ffff;
  margin: 30px;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 50px;
  padding-bottom: 200px;
}

.right-Username-container {
  width: 100%;
}

.test-user-container {
  display: flex;
}

.button-attach-container-hse {
  display: flex;
  justify-content: flex-end;
}

.button-attach-hse {
  height: 50px;
  border-radius: 5px;
  background-color: #ffffff;
  border: 1px solid var(--second-main-color);
  /* top:20px; */
  text-align: center;
  color: var(--second-main-color);
  width: 100%;
  font-family: "Sarabun";
  font-style: normal;
  padding-right: 40px;
  padding-left: 40px;
  margin-left: 30px;
  font-size: 18px;
}

.form-container-with-bottomspace-documents {
  left: 150px;
  top: 918px;
  background: #ffff;
  margin: 30px;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 50px;
  padding-bottom: 250px;
}

.vendor-input-label-terms {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  margin-top: 15px;
  color: #adb2b9;
  margin-bottom: 15px;
}

.Privacy-notice {
  color: #eb0a45;
}
.Privacy-notice:hover {
  color: #eb0a45;
}

.dropdown {
  border-radius: 5px;
}

.dropdown-hse {
  border: 1px solid #adb2b9;
  background-color: #ffff;
  border-radius: 5px;
  margin-top: -22px;
}

@media screen and (min-width: 200px) and (max-width: 520px) {
  .button-submit {
    margin-right: 30px;
    /* width: 150px; */
    width: 100px;
  }

  .button-submit-back {
    margin-right: 0px;
    /* width: 150px; */
    width: 100px;
  }

  .buttons {
    display: flex;
  }

  .form-container-with-bottomspace {
    padding-bottom: 100px;
  }

  .form-container-with-bottomspace-finanacial {
    padding-bottom: 100px;
  }

  .input-border.registration {
    min-width: 60%;
  }

  .button-attach-hse {
    width: 50%;
    padding-right: 0%;
    padding-left: 0%;
  }

  .button-attach-container-hse {
    display: flex;
  }

  /* .registration {
      width: auto;
    } */
}

@media screen and (min-width: 200px) and (max-width: 820px) {
  .registration-hse {
    width: 100%;
  }
}

.announce-navbar {
  display: flex;
  justify-content: center;
  align-items: center;
}

.regstration-header {
  background-image: url(/public/images/emailpic1.svg);
  /* -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover; */
  background-size: cover;
  /* height: auto; */
  margin-top: -100px;
  padding: 100px 0;
  /* background-repeat: no-repeat;
    background-size: cover; */
  width: 100%;
  height: 400px;
  /* left: 0px;
    top: 0px; */
}

[hidden] {
  display: none !important;
}

.uploadFile-document {
  height: 50px;
  border-radius: 5px;
  background-color: #ffffff;
  border: 1px solid var(--second-main-color);
  /* top:20px; */
  text-align: center;
  color: var(--second-main-color);
  /* width: 20%; */
  white-space: nowrap;
  font-family: "Sarabun";
  font-style: normal;
  padding-right: 40px;
  padding-left: 40px;
  margin-left: 30px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.uploadFile-document-disabled {
  height: 50px;
  border-radius: 5px;
  background-color: var(--disabled-background-color) !important;
  color: var(--disabled-text-color) !important;
  border: 1px solid #303031;
  /* top:20px; */
  text-align: center;
  /* width: 20%; */
  font-family: "Sarabun";
  font-style: normal;
  padding-right: 40px;
  padding-left: 40px;
  margin-left: 30px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  margin-bottom: 10px;
}
.uploadFile-document-disabled:hover {
  cursor: not-allowed;
}
.uploadFile-document-supplier-hse {
  height: 50px;
  border-radius: 5px;
  background-color: #ffffff;
  border: 1px solid var(--second-main-color);
  /* top:20px; */
  text-align: center;
  color: var(--second-main-color);
  width: 120px;
  font-family: "Sarabun";
  font-style: normal;
  /* padding-right: 40px;
    padding-left: 40px;
    margin-left: 30px; */
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.input-border-documents {
  border: 1px solid #adb2b9;
  border-radius: 10px;
  width: 82%;
  margin-bottom: 15px;
  height: 50px;
  padding: 10px;
}

.input-border-documents-supplier-hse {
  border: 1px solid #adb2b9;
  border-radius: 10px;
  width: 75%;
  margin-bottom: 15px;
  height: 50px;
  padding-left: 10px;
  margin-right: 20px;
}

.textbox-container-specify {
  display: flex;
  justify-content: flex-end;
  margin-left: 20px;
}
.right-container-specify {
  width: 50%;
}

.dropdown-multi {
  border: 1px solid #adb2b9;
  border-radius: 10px;
  height: 40px;
  width: 100%;
  max-width: 480px;
  min-width: 100%;
}

.fake-input {
  width: 100%;
  height: 50px;
  border-radius: 5px;
}

.question-mark {
  width: 30px;
  height: 30px;
}

.sub-category-drop .actions-btn {
  display: none !important;
}

.sub-category-drop {
  border: unset !important;
}
.sub-category-drop .dropdown .btn-light {
  border: 1px solid #adb2b9;
  border-radius: 10px;
  height: 40px !important;
}

.btn-light {
  height: 37px !important;
  padding-right: 32px;
  background: transparent;
}

/* country code drop down styles */

.react-tel-input .form-control {
  height: 50px !important;
  width: 100% !important;
  border: 1px solid #adb2b9 !important ;
  border-radius: 10px !important;
}

/* custom drop down style for all select fields */

.textbox-container select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("../../Assets/Svg/dropdown-icon.svg");
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;
  padding-right: 20px !important;
}

.textbox-container select::-ms-expand {
  display: none;
}

@media screen and (min-width: 768px) and (max-width: 1300px) {
  .dropdown-hse {
    width: 100%;
    max-width: 540px;
  }
}

/* multi drop down styles fixing in 1920 screen */

@media screen and (min-width: 1300px) and (max-width: 1920px) {
  .dropdown-multi {
    width: 100%;
    max-width: 610px;
    min-width: 100%;
  }
  .dropdown-hse {
    width: 100%;
    max-width: 865px !important;
    margin-top: 0px;
    min-width: 100%;
  }
}

.toggle-password-button {
  background-color: #ffffff;
}

.toggle-password-button img {
  width: 15px;
}

.rmsc .dropdown-container {
  height: 31px !important;
  border: 0px !important;
}

.rmsc {
  padding-left: 10px;
  padding-right: 10px;
}

.rmsc .dropdown-container:focus-within{
  box-shadow: none !important;
}


/* Apply styles to the date input */
input[type="date"] {
  font-size: 16px;
}

/* Style the date picker arrow icon */
/* input[type="date"]::-webkit-calendar-picker-indicator {
  color: #333; 
} */


/* Style the selected date */
input[type="date"]::-webkit-datetime-edit {
  color: #333;
}

/* Change the date picker arrow icon for Webkit browsers */
input[type="date"]::-webkit-calendar-picker-indicator {
  background: url("../../Assets/Svg/calender-icon.svg") no-repeat;
  background-size: contain;
  width: 20px; /* Adjust the width as needed */
  height: 20px; /* Adjust the height as needed */
  opacity: 1; /* Ensure the icon is fully visible */
  cursor: pointer;
  margin-left: 5px;
}
