.stat-card {
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    border: 1px solid #EBEBEB;
    background-color: #fff;
}

.stat-card-title {
    font-family: 'Sarabun';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    color: #103A5D;
}

.stat-card-count {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    color: #272830;
}

.stat-card-view {
    font-family: 'Sarabun';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    text-decoration-line: underline;
    text-transform: capitalize;
    color: #103A5D;
}