.dashboard-header {
  position: fixed;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  -webkit-box-shadow: 0 10px 6px -6px #777;
  -moz-box-shadow: 0 10px 6px -6px #777;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  /* padding-right: 50px; */
  width: 100%;
  background: #FDFDFF;
  border: 1px solid #EBEBEB;
  z-index: 999;
}

.dashboard-vendor-circle {
  background: #103a5d;
  color: white;
  border-radius: 50px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard-vendor-name {
  font-family: "Arial";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #103a5d;
}

.dashboard-vendor-sub-name {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: rgba(16, 58, 93, 0.6);
}

.flag-dropdown {
  position: absolute;
  top: 75px;
  margin-left: -52px;
  width: 195px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  z-index: 7;
}

.flag-img-div img {
  width: 32px;
  height: 32px;
}

.flag-img-div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5px;
  gap: 10px;
  cursor: pointer;
}
.flag-img-div:hover {
  background-color: #f1f1f1;
}

.flag-dropdown p {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-transform: capitalize;
  color: var(--main-color);
}