.dropzone-heading {
  color: var(--second-main-color);
  font-family: Sarabun;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.upload-file-dropzone {
  width: 100%;
  border: 1px dashed #999;
  background: #fff;
  margin-top: 4px;
}

.drop-zone-document-title-div {
  border-bottom: 1px solid #adb2b9;
}

.drop-zone-document-title {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: var(--second-main-color);
  border-bottom: 2px solid var(--second-main-color);
}

.uploaded-document-show {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ebebeb;
  background: #fafbff;
  padding: 10px;
}

.dropzone-file-name {
  color: var(--second-main-color);
  text-align: center;
  font-family: Sarabun;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
