.vp-files-main {
  width: 100%;
  border-bottom: 1px solid #adb2b9;
  margin-bottom: 15px;
}
.vp-file-count {
  color: var(--main-color);
  line-height: 21px;
  font-weight: 700;
  font-size: 16px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.vp-file-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 30px;
  gap: 10px;
  /* position: absolute; */
  height: 50px;
  background: #fdfdff;
  border: 1px solid #ebebeb;
  margin-bottom: 20px;
}


.vp-file-div {
    position: relative;
  }
  
  .cross-icon {
    position: absolute;
    top: -7px;
    right: -6px;
  }
  