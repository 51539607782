.upload-po-file {
  width: 500px;
  border: 1px dashed black;
  background-color: #fff;
}

.upload-po-image-btn {
  background: #103a5d;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  color: white;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
}

.po-document-file {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #103a5d;
  border-bottom: 2px solid #103a5d;
}

.po-document-file-name {
  background: #fafbff;
  padding: 10px;
}

.upload-po-sub-header {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  display: flex;
  color: #103a5d;
}

.bgc-po-style-comp {
  /* display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fafbff; */
  display: flex;
  background-color: #fafbff;
  justify-content: space-around;
  gap: 361px;
  align-self: center;
}

.po-upload-main-comp {
  /* border: 1px solid #ebebeb; */
  /* border: 1px solid #ebebeb; */
  display: flex;
  width: 100%;
  padding: 20px 30px;
  flex-direction: column;
  align-items: center;
  /* gap: 20px; */
  border: 1px solid #ebebeb;
  background: #fafbff;
}

.upload-po-document-show {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #dadada;
}

.upload-drop-zone-document-title {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: var(--main-color);
  border-bottom: 2px solid var(--main-color);
}
